<template>
	<div class="xw-car-keyboard">
		<el-popover ref="xw-car-keyboard" placement="bottom-start" trigger="click">
			<div class="xw-car-keyboard-popover">
				<div v-for="(item,index) in list" :key="index" @click="keyboard(item)">
					{{item}}
				</div>
				<div @click="del()" class="active">
					<i class="el-icon-delete" />
				</div>
				<div @click="close()" class="active">
					<i class="el-icon-close" />
				</div>
			</div>
		</el-popover>
		<el-input v-popover:xw-car-keyboard readonly v-model.trim="plateNumber" placeholder="请输入车牌号" clearable
			@input="change" @focus="focus" v-bind="$attrs" style="width:100%;">
			<template v-for="(item,key) in $slots" v-if="!slotValue" :slot="key">
				<slot :name="key" />
			</template>
			<el-select v-bind="$attrs" v-model="plateColor" @change="changeColor" :slot="slotValue"
				v-if="slotValue && plateColour" class="plate-color-select">
				<el-option v-for="(item, index) in plateColorList" :key="index" :label="item" :value="index" v-if="index" />
			</el-select>
		</el-input>
	</div>
</template>

<script>
	export default {
		props: {
			value: {
				type: String,
				default: ''
			},
			validateEvent: {
				type: Boolean,
				default: false
			},
			plateColour: {
				type: [String, Number],
				default: ''
			},
			slotValue: {
				type: [Boolean, String],
				default: false
			}
		},
		data() {
			return {
				visible: false,
				plateNumber: '',
				plateColor: '',
				list: [],
				cityList: [
					1,
					2,
					3,
					4,
					5,
					6,
					7,
					8,
					9,
					'京',
					'沪',
					'粤',
					'津',
					'冀',
					'豫',
					'云',
					'辽',
					'黑',
					'湘',
					'皖',
					'鲁',
					'苏',
					'浙',
					'赣',
					'鄂',
					'桂',
					'甘',
					'晋',
					'陕',
					'蒙',
					'吉',
					'闽',
					'贵',
					'渝',
					'川',
					'青',
					'琼',
					'宁',
					'藏',
					'新',
				],
				engList: [
					1,
					2,
					3,
					4,
					5,
					6,
					7,
					8,
					9,
					0,
					'Q',
					'W',
					'E',
					'R',
					'T',
					'Y',
					'U',
					'O',
					'P',
					'A',
					'S',
					'D',
					'F',
					'G',
					'H',
					'J',
					'K',
					'L',
					'Z',
					'X',
					'C',
					'V',
					'B',
					'N',
					'M',
					'港',
					'澳',
					'学',
					'领',
					'警',
					'军'
				]
			}
		},
		watch: {
			value() {
				this.plateNumber = this.value
			},
			plateColour() {
				this.plateColor = this.plateColour * 1
			}
		},
		methods: {
			changeColor(e) {
				this.$emit('update:plateColour', e);
			},
			keyboard(item) {
				this.plateNumber += item
				this.change()
			},
			del() {
				this.plateNumber = this.plateNumber.slice(0, this.plateNumber.length - 1)
				this.change()
			},
			close() {
				this.plateNumber = ''
				this.change()
			},
			focus() {
				const len = this.plateNumber.length
				if (!len) {
					this.list = this.cityList
				} else {
					this.list = this.engList
				}
			},
			change() {
				const value = this.plateNumber
				this.oldPlateColor = this.plateColor
				if (value.length > 7) {
					this.changeColor(5)
				} else {
					this.changeColor(1)
				}
				this.$emit('input', value)
				this.focus()
				if (this.validateEvent) {
					this.$parent.$emit('el.form.blur', [value])
				}
			},
		}
	}
</script>

<style lang="scss">
	.xw-car-keyboard {
		display: inline-block;
		width: 100%;

		&-popover {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			max-width: 360px;

			>div {
				width: 30px;
				height: 30px;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 20px;
				margin: 5px;

				&.active,
				&:hover {
					color: #fff;
					background: #007aff;
					border-radius: 10px;
				}
				&.active+.active{
					background: #f11;
				}

				&:active {
					// border: 1px solid #007aff;
					border-radius: 10px;
					background: rgba(#007aff, 0.6);
				}
			}

		}

		.el-select .el-input {
			width: 100px;
		}

		.plate-color-select .el-input-group__prepend {
			background-color: #fff;
		}

	}
</style>