<template>
	<div :class="'el-select el-select--'+size" :clearable="clearable && value">
		<div :class="'el-input el-input--suffix el-input--'+size">
			<input readonly autocomplete="off" placeholder="请选择" class="el-input__inner" :value="value">
			<span class="el-input__suffix">
				<span class="el-input__suffix-inner">
					<i class="el-select__caret el-input__icon el-icon-circle-close" @click.stop="$emit('input','')" />
					<i class="el-select__caret el-input__icon el-icon-arrow-up" />
				</span>
			</span>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['value', 'clearable'],
		data() {
			return {
				size: sessionStorage.getItem('ElementSize') || 'mini',

			}
		},
		mounted() {

		},
		methods: {

		}
	}
</script>

<style scoped lang="scss">
	.el-select[clearable] {
		.el-icon-circle-close {
			display: none;
		}

		&:hover {
			.el-icon-circle-close {
				display: block;
			}

			.el-icon-arrow-up {
				display: none;
			}
		}
	}
</style>