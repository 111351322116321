/**
 * Created by sxw at 2023/10/6
 * @param {Object} data
 * get  adornParams()
 * post adornData()
 */
import http from '@/api'
import {
	objectToQueryString
} from '@/utils'

export function list(data) {
	delete data.total
	return http({
		url: http.adornUrl("/park/testate/getAllList"),
		method: "get",
		params: http.adornParams(data),
	})
}

export function listAll() {
	return http({
		url: http.adornUrl("/park/testate/getAll"),
		method: "get",
		params: http.adornParams(),
	})
}

export function info(data) {
	return http({
		url: http.adornUrl(
			`/${data.type==4?'equ':'park'}/${['testate','tpark','area','lane','tmonitoring'][data.type || 0]}/info/` +
			data.id),
		method: "get",
	})
}

export function saveOrUpdate(data) {
	return http({
		url: http.adornUrl(`/park/testate/${!data.id?'save':'update'}`),
		method: "post",
		data: http.adornData(data),
	})
}

export function del(data) {
	return http({
		url: http.adornUrl(
			`/${data.type==4?'equ':'park'}/${['testate','tpark','area','lane','tmonitoring'][data.type || 0]}/delete/${data.id}`
			),
		method: "get",
	})
}

export function configInfo(data) {
	return http({
		url: http.adornUrl("/park/testate/objectConfigInfo"),
		method: "get",
		params: http.adornParams(data),
	})
}

export function saveConfig(data) {
	return http({
		url: http.adornUrl("/park/testate/objectConfig"),
		method: "post",
		data: http.adornData(data),
	})
}

export function extraInfoList(data) {
	return http({
		url: http.adornUrl("/park/testate/extraInfoList"),
		method: "get",
		params: http.adornParams(data),
	})
}

export function extraInfoSave(data) {
	return http({
		url: http.adornUrl("/park/testate/extraInfoAdd"),
		method: "post",
		data: http.adornData(data),
	})
}

export function extraInfoUpdate(data) {
	return http({
		url: http.adornUrl("/park/testate/extraInfoUpdate"),
		method: "post",
		data: http.adornData(data),
	})
}


export function exprotExcel(data) {
	//下载文件 　　js模拟点击a标签进行下载
	delete data.total
	var link = document.createElement('a')
	link.href = http.adornUrl(
		'/order/ttransactionrecord/estateExportExcel', 1) + '&' + objectToQueryString(data)
	link.target = '_blank'
	link.click();
}