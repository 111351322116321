// 定义混入的内容
import {
	listAll,
	extraInfoList,
	extraInfoSave,
	configInfo
} from "@/api/company";
import {
	list as estateList,
} from "@/api/estate";
import {
	list as parkList,
} from '@/api/park'
import {
	list as carTypeList,
} from "@/api/park/carType";
import {
	list as areaList,
} from "@/api/area";
import {
	list as equList
} from "@/api/park/equ";
import {
	list as laneList
} from "@/api/park/lane";
import {
	getObjectList
} from "@/api/car";

export default {
	data() {
		return {
			configInfo: {
				isPlateColor: 1,
				payChannel: []
			},
			elDialogBind: {
				'append-to-body': true,
				'close-on-click-modal': false,
				// 'destroy-on-close':true
			},
			elDrawerBind: {
				'destroy-on-close': true,
				'show-close': false,
				size: "50%",
				// modal:false
			},
			extraList: [],
			laneList: [],
			equList: [],
			areaList: [],
			companyList: [],
			parkList: [],
			estateList: [],
			carTypeList: [],
			payTypeList: ['', '钱包支付', '支付宝支付', '微信支付', '现金支付', 'ETC支付'],
			plateColorList: ['通用', '蓝色车牌', '黄色车牌', '白色车牌', '黑色车牌', '绿色车牌', '黄绿色车牌'],
			noPlateColorList: {
				'-1': '非蓝色车牌',
				'-2': '非黄色车牌',
				'-3': '非白色车牌',
				'-4': '非黑色车牌',
				'-5': '非绿色车牌',
				'-6': '非黄绿色车牌'
			},
			brandList: ['', 'HKWS', 'DH', 'ZS', 'YS', 'QY', 'KT', 'HX', 'LK'],
			// brandList: ['', '海康威视', '大华', '臻识', '宇视', '芊羽', '科拓', '华夏'],
			boardList: ['', '玲贯', '科发', '方控', '泰瑞达'],
			carTypeDefault: ['临时车', '新能源车', '管控车', '白名单车', '公务车', '异常车', '僵尸车', '访客车'],
			amountList: [{
					label: '大于等于0元',
					value: 0
				},
				{
					label: '大于等于3元',
					value: 3
				},
				{
					label: '大于等于5元',
					value: 5
				},
				{
					label: '大于等于10元',
					value: 10
				},
				{
					label: '大于等于15元',
					value: 15
				},
				{
					label: '大于等于20元',
					value: 20
				}
			],
			dateTimeOld: {
				shortcuts: [{
					text: '今天',
					onClick(picker) {
						picker.$emit('pick', new Date());
					}
				}, {
					text: '昨天',
					onClick(picker) {
						const date = new Date();
						date.setTime(date.getTime() - 3600 * 1000 * 24);
						picker.$emit('pick', date);
					}
				}, {
					text: '一周前',
					onClick(picker) {
						const date = new Date();
						date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', date);
					}
				}, {
					text: '一月前',
					onClick(picker) {
						const date = new Date();
						date.setTime(date.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', date);
					}
				}]
			},
			dateTimeNew: {
				shortcuts: [{
					text: '今天',
					onClick(picker) {
						picker.$emit('pick', new Date());
					}
				}, {
					text: '明天',
					onClick(picker) {
						const date = new Date();
						date.setTime(date.getTime() + 3600 * 1000 * 24);
						picker.$emit('pick', date);
					}
				}, {
					text: '一周',
					onClick(picker) {
						const date = new Date();
						date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
						picker.$emit('pick', date);
					}
				}, {
					text: '一个月',
					onClick(picker) {
						const date = new Date();
						date.setTime(date.getTime() + 3600 * 1000 * 24 * 30);
						picker.$emit('pick', date);
					}
				}]
			},
			pickerDateTime: {
				shortcuts: [{
						text: '今天',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setHours(0, 0, 0, 0);
							start.setTime(start.getTime());
							end.setTime(end.getTime());
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '昨天',
						onClick(picker) {
							const end = new Date();
							const start = new Date();

							start.setHours(0, 0, 0, 0);
							end.setHours(23, 59, 59, 0);
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
							end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);

							picker.$emit('pick', [start, end]);
						}
					},
					{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					},
					{
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}
				]
			},
		}
	},
	methods: {
		getTimeVal(value) {
			let ret = ''
			const h = Math.floor((value || 0) / 60),
				m = ((value || 0) % 60)
			if (h) {
				ret += h + '小时'
			}
			if (m >= 0 && !(m == 0 && h)) {
				ret += m + '分钟'
			}
			return ret
		},
		requireUrl(url) {
			try {
				return require(`@/assets/img${url}`)
			} catch (e) {
				return ''
			}
		},
		// 表格样式
		cellClassMame({
			row,
			column
		}) {
			if (column.property == 'plateNumber' || column.property == 'plateNumbser' || column.property == 'palteNumber') {
				return this.configInfo.isPlateColor == 1 ? ('plateColor plateColor' + (row.plateColour || row.plateColor)) : ''
			}
		},
		// 获取全部运营方
		async getCompanyList() {
			await listAll().then(({
				data
			}) => {
				if (data && data.result) {
					this.companyList = data.data;
				} else {
					this.companyList = [];
				}
			})
		},
		async getObjectList() {
			await getObjectList().then(({
				data
			}) => {
				if (data && data.result && data.data) {
					this.companyList = data.data
				}
			})
		},
		async extraInfoList(data) {
			await extraInfoList(data).then(({
				data
			}) => {
				if (data && data.result && data.data && data.data.list) {
					this.extraList = data.data.list
				}
			})
		},
		async extraInfoSave(data) {
			await extraInfoSave(data)
		},
		async getConfigInfo(data) {
			await configInfo(data).then(({
				data
			}) => {
				if (data && data.result && data.data) {
					this.configInfo = data.data
				}
			})
		},
		// 时间区间格式化
		datetimerange() {
			let startTime = '',
				endTime = ''
			if (this.time && this.time.length) {
				startTime = this.time[0]
				endTime = this.time[1]
			}
			this.formData.endTime = endTime
			this.formData.startTime = startTime
		},
		// 获取车场类型
		async getCarTypeList(dataForm = {}) {
			if (typeof dataForm == 'string') {
				dataForm = {
					objectId: dataForm
				}
			}
			await carTypeList({
					page: 1,
					limit: 100000000,
					...dataForm
				}).then(({
					data
				}) => {
					if (data && data.result) {
						this.carTypeList = data.data.list;
					}
				})
				.catch((e) => {});
		},
		// 获取所有公司列表
		async getestateList() {
			await estateList({
				page: 1,
				limit: 100000000,
			}).then(({
				data
			}) => {
				if (data && data.result) {
					this.estateList = data.data.list;
				} else {
					this.estateList = [];
				}
			});
		},
		//车场列表
		async getParkList(objectId = '') {
			await parkList({
				page: 1,
				limit: 100000000,
				objectId
			}).then(({
				data
			}) => {
				if (data && data.result && data.data && data.data.list) {
					this.parkList = data.data.list;
				} else {
					this.parkList = []
				}
			})
		},
		async getAreaList(dataForm = {}) {
			if (typeof dataForm == 'string') {
				dataForm = {
					parkId: dataForm
				}
			}
			await areaList({
				page: 1,
				limit: 100000000,
				...dataForm
			}).then(({
				data
			}) => {
				if (data && data.result && data.data && data.data.list && data.data.list.length) {
					this.areaList = data.data.list;
				} else {
					this.areaList = [];
				}
			});
		},
		async getLaneList(dataForm = {}) {
			if (typeof dataForm == 'string') {
				dataForm = {
					parkId: dataForm
				}
			}
			await laneList({
				page: 1,
				limit: 100000000,
				...dataForm
			}).then(({
				data
			}) => {
				if (data && data.result && data.data && data.data.list && data.data.list.length) {
					this.laneList = data.data.list
				} else {
					this.equList = []
				}
			})
		},
		async getEquList(dataForm = {}) {
			if (typeof dataForm == 'string') {
				dataForm = {
					parkId: dataForm
				}
			}
			await equList({
				page: 1,
				limit: 100000000,
				...dataForm
			}).then(({
				data
			}) => {
				if (data && data.result && data.data && data.data.list && data.data.list.length) {
					this.equList = data.data.list
				} else {
					this.equList = []
				}
			})
		},
		refreshDataList() {
			this.$message({
				message: "操作成功",
				type: "success",
				duration: 1000,
				onClose: () => {
					this.visible = false;
					this.$emit("refreshDataList");
				},
			});
		},
		// 带提示的删除等
		async handle(data, api, tip, callback) {
			try {
				let loading = null
				await this.$confirm(
						tip,
						"提示", {
							dangerouslyUseHTMLString: true,
							confirmButtonText: "确定",
							cancelButtonText: "取消",
							type: "warning",
						}
					)
					.then(() => {
						loading = this.$loading({
							lock: true,
							text: 'Loading',
							spinner: 'el-icon-loading',
							background: 'rgba(0, 0, 0, 0.7)'
						})
						api(data).then(({
							data
						}) => {
							if (data && data.result) {
								this.$message({
									message: "操作成功",
									type: "success",
									duration: 1500,
									onClose: () => {
										loading.close();
										if (callback) callback(data.data)
									},
								});
							} else {
								this.$message.error(data.msg);
								loading.close();
							}
						}).catch((err) => {
							console.log(err);
							this.$message.error('操作失败，请稍后重试');
							loading.close()
						})
					})
			} catch (e) {
				console.log('catch', e)
			}
		},
		submitEdit(dataForm, api, formKey = 'dataForm', callback) {
			try {
				if (this.$refs[formKey]) {

					this.$refs[formKey].validate((valid) => {
						if (valid) {
							this.submitEditApi(dataForm, api, callback)
						}
					});
				} else {
					this.submitEditApi(dataForm, api, callback)
				}
			} catch (e) {
				console.log('catch', e)
			}
		},
		submitEditApi(dataForm, api, callback) {
			try {
				const loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				})
				api(dataForm).then(({
					data
				}) => {
					if (data && data.result) {
						this.$message({
							message: "操作成功",
							type: "success",
							duration: 1000,
							onClose: () => {
								this.visible = false;
								loading.close();
								if (callback) callback(data.data)
								this.$emit("refreshDataList", data.data);
							},
						});
					} else {
						loading.close();
						this.$message.error(data.msg);
					}
				}).catch((err) => {
					console.log(err);
					loading.close()
				})
			} catch (e) {
				console.log('catch', e)
			}
		}
	}
}