<template>
	<div class="xw-table" ref="tableBox">
		<el-tables :header-cell-style="{background:'#FAFAFA'}" :data="data" v-bind="$attrs" style="width: 100%"
			v-if="tableHeight" :height="tableHeight + 'px'" @selection-change="selectionChange" ref="xwElTable">
			<el-table-column type="selection" width="55" v-if="selection" />
			<el-table-column v-if="showIndex" type="index" width="80" label="序号" :header-align="align" :align="align" />
			<el-table-column v-bind="item" v-for="(item,index) in columnFilter" :key="index"
				:show-overflow-tooltip="showOverflowTooltip" :header-align="item.align || align" :align="item.align||align"
				:width="item.width || 'auto'" :min-width="item.minWidth || 'null'" :fixed="item.fixed">
				<!-- sortable -->
				<template slot="header" slot-scope="scope">
					<span>
						{{item.label}}
					</span>
					<el-popover placement="bottom" width="100" trigger="click"
						v-if="!($scopedSlots.default && operateWidth) && index==columnFilter.length-1 && showFilterColumn">
						<div slot="reference"
							style="font-size: 24px;position: absolute;right: 10px;top:50%;transform: translateY(-50%);cursor: pointer;">
							<svg-icon name="filter" />
						</div>
						<el-checkbox-group v-model="checkList" @change="filterColumn">
							<el-checkbox style="width: 100%;margin-bottom: 5px;" :label="o.label" v-for="(o,i) in column" :key="i" />
						</el-checkbox-group>
						<div style="border-top: 1px solid #ddd;">
							<el-button type="text" @click="checkList=column.map(item => item.label);filterColumn()">全选</el-button>
							<!-- <el-button type="text" :disabled="checkList.length<1" @click="filterColumn">筛选</el-button> -->
							<!-- <el-button type="text" @click="checkList=[]">重置</el-button> -->
						</div>
					</el-popover>
				</template>
				<template slot-scope="scope">
					<template v-if="formatter.includes(item.prop)">
						<slot :name='item.prop' v-bind="scope" />
					</template>
					<template v-else>{{tableFilter( scope.row, scope.column,scope.row[item.prop])}}</template>
				</template>
			</el-table-column>
			<el-table-column :width="operateWidth" label="操作" :header-align="align" :align="align"
				v-if="$scopedSlots.default && operateWidth && showFilterColumn" :fixed="fixed">
				<template slot="header" slot-scope="scope">
					<span>
						操作
					</span>
					<el-popover placement="bottom" width="0" trigger="click" :visible-arrow="false" >
						<div slot="reference"
							style="font-size: 24px;position: absolute;right: 10px;top:50%;transform: translateY(-50%);cursor: pointer;">
							<svg-icon name="filter" />
						</div>
						<el-checkbox-group v-model="checkList" @change="filterColumn" style="max-height: 80vh;overflow-y: auto;overflow-x: hidden;width: 100%;">
							<el-checkbox style="width: 100%;margin:0;margin-bottom: 5px;overflow: hidden;text-overflow: ellipsis;" :label="o.label" v-for="(o,i) in column" :key="i" />
						</el-checkbox-group>
						<div style="border-top: 1px solid #ddd;">
							<!-- <el-button type="text" :disabled="checkList.length<1" @click="filterColumn">筛选</el-button> -->
							<el-button type="text" @click="checkList=column.map(item => item.label);filterColumn()">全选</el-button>
							<!-- <el-button type="text" @click="checkList=[];filterColumn()">重置</el-button> -->
						</div>
					</el-popover>

				</template>
				<template slot-scope="scope">
					<slot v-bind="scope" />
				</template>
			</el-table-column>
		</el-tables>
		<div class="xw-pagination" v-if="page">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
				:page-sizes="sizes" :page-size="limit" :total="total" layout="total, sizes, prev, pager, next, jumper" />
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			operateWidth: {
				type: [Number, String],
				default: 150
			},
			tableFilter: {
				type: Function,
				default (row, column, value) {
					if (value === '' || value === null || value === undefined) {
						return '--'
					}
					return value
				}
			},
			data: {
				type: Array,
				default () {
					return []
				}
			},
			formatter: {
				type: Array,
				default () {
					return []
				}
			},
			showIndex: {
				type: Boolean,
				default: false
			},
			column: {
				type: Array,
				default () {
					return []
				}
			},
			showFilterColumn: {
				type: Boolean,
				default: true
			},
			page: {
				type: [Number, String],
				default: 0
			},
			sizes: {
				type: Array,
				default () {
					return [20, 50, 100]
				}
			},
			limit: {
				type: [Number, String],
				default: 20
			},
			total: {
				type: [Number, String],
				default: 0
			},
			showOverflowTooltip: {
				type: Boolean,
				default: false
			},
			selection: {
				type: Boolean,
				default: false
			},
			fixed: {
				type: [String, Boolean],
				default: false
			},
			align: {
				type: String,
				default: 'center'
			}
		},
		data() {
			return {
				tableHeight: 0,
				checkList: [],
				columnFilter: []
			}
		},
		// computed: {
		// 	tableHeight() {
		// 		if (this.$refs.tableBox) {
		// 			return this.$refs.tableBox.clientHeight - (this.page ? 40 : 0);
		// 		}
		// 		return 0
		// 	}
		// },
		watch: {
			data: {
				handler() {
					this.initTableHeight()
				},
				deep: true
			},
			column() {
				this.columnFilter = this.column
				this.checkList = this.columnFilter.map(item => item.label)
				// console.log(this.columnFilter, 345)
			},
		},
		created() {
			// mounted() {
			//减去翻页的高度，因为容器包含了表格和翻页
			this.initTableHeight()
			this.columnFilter = this.column
			this.checkList = this.columnFilter.map(item => item.label)
			// console.log(this.columnFilter, 234)
		},
		methods: {
			filterColumn() {
				this.columnFilter = this.column.filter(item => this.checkList.includes(item.label))
			},
			initTableHeight() {
				this.$nextTick(() => {
					const tableHeight = this.$refs.tableBox.clientHeight - (this.page ? 40 : 0);
					if (tableHeight > this.tableHeight) {
						this.tableHeight = tableHeight
					}
					if (this.tableHeight) {
						this.$nextTick(() => {
							this.$refs.xwElTable.doLayout()
						})
					}
				})
			},
			handleSizeChange(val) {
				this.$emit('page-size', val)
			},
			handleCurrentChange(val) {
				this.$emit('page-change', val)
			},
			selectionChange(val) {
				this.$emit('selection-change', val)
			}
		}
	}
</script>

<style lang="scss">
	.xw-pagination {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		height: 40px;
	}
</style>